import { Link } from 'react-router-dom';
import { ContainerButton, Button} from './styles';

export default function BackButton() {

    return (
        <ContainerButton>
            <Link to="/blog">
                <Button>
                    Voltar
                </Button>
            </Link>
        </ContainerButton>
    )
}