import styled from 'styled-components';
import { AiOutlineClose } from "react-icons/ai";

export const Container = styled.div`
    height: 125vw;
    width: 100%;
    left: 0;
    top: 0;
    position: absolute;
    z-index: 10;
    display: flex;
    justify-content: center;  
    background: white;
    z-index: 99;
    opacity: 85%;

    @media (max-width: 480px) {
        margin-top: 0%;     
        width: 100%;
        height: 210vw;
        align-items: center;
        justify-content: center;
   
    }
`;

export const Title = styled.h1`
    top: 374px;
    left: 413px;
    width: 687px;
    height: 232px;
    position: fixed;
    font-size: 48px;
    font-weight: 400;
    color: #000000;
    opacity: 1;

    @media (min-width: 480px) and (max-width: 1440px) {
        top: 218px;
        left: 119px;
        width: 687px;
        height: 232px;
    }
    @media (max-width: 480px) {
        top: 164px;
        left: 16px;
        width: 328px;
        height: 322px;
    }
`;

export const Closeicon = styled(AiOutlineClose)`
    width: 25px;
    height: 25px; 
    color: black;
    top: 50px;
    left: 1483px;
    position: absolute;

    @media (min-width: 480px) and (max-width: 1440px) {
        top: 10px;
        left: 1206px;
        width: 25px;
        height: 25px;
    }

    @media (max-width: 480px) {
        left: 80%;
        top: 10%;
        width: 30px;
        height: 30px;
        position: absolute;
    }
`;