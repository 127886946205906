import styled from 'styled-components'

const Container = styled.div`
    background-color: #f9f9f9;
    margin: 0 auto;
    max-width: 287px;
    padding: 80px 16px 0;

    @media (min-width: 768px) {
        max-width: 872px;
    }
`

const Title = styled.h1`
    font-size: 28px;
    font-weight: 700;
    line-height: 42px;
    margin-bottom: 24px;

    @media (min-width: 768px) {
        font-size: 32px;
    }
`

const Description = styled.p`
    font-size: 16px;
    font-weight: 300;
    line-height: 24px;
    margin-bottom: 24px;

    @media (min-width: 768px) {
        margin-bottom: 32px;
    }
`

const ImageContainer = styled.div`
    background-color: ${(props) => props.backgroundColor};
    height: 124px;
    margin-bottom: 24px;
    width: 100%;

    @media (min-width: 768px) {
        height: 329px;
        margin-bottom: 32px;
    }
`

const Image = styled.img`
    height: 100%;
    object-fit: cover;
    width: 87%;
`

const ButtonHolder = styled.div`
    display: inline-block;
    height: 51px;
    margin-bottom: 48px;
    position: relative;
    width: 255px;
`

const ButtonText = styled.div`
    display: inline-block;
    height: 51px;
    width: 255px;

    &:after {
        color: #fff;
        content: '${(props) => props.text}';
        font-family: 'Roboto', sans-serif;
        font-size: 16px;
        font-weight: 400;
        height: 100%;
        left: 50%;
        line-height: 51px;
        pointer-events: none;
        position: absolute;
        text-align: center;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
    }

    a {
      display: inline-block;
      height: 51px;
      text-decoration: none;
      transform: scale(1);
      transition: all 0.3s ease;
      width: 255px;

      &:hover {
        transform: scale(0.9);

        button {
          line-height: 41px;
        }
      }
    }

    button {
        background-color: ${(props) => props.backgroundColor};
        border: 0;
        color: transparent;
        cursor: pointer;
        font-size: 0;
        height: 100%;
        text-align: center;
        transition: all 0.3s ease;
        width: 100%;
    }
`

export { Container, Title, Description, ImageContainer,
        Image, ButtonHolder, ButtonText }
