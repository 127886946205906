import { useState } from 'react'

import { Container, Oqentregamos, LinkOqentregamos, FaleCaGente, LinkProposta, Proposta, Trabalhar, Endereco, Linkedin, Texto, TextoT } from './styles'

import LinkedinSVG from '../../../assets/SVG/linkedin-black.svg'

import { useContato } from '../../../contexts/contato'

function Menu({ onClose = () => { } }) {
  const [pathname] = useState(window.location.pathname)
  const [origin] = useState(window.location.origin)

  const { handleModalContact } = useContato()

  return (
    <Container>
      {pathname === '/' ?
        <>
          <Oqentregamos href='#o-que-entregamos' onClick={onClose}><Texto>O que entregamos</Texto></Oqentregamos>
          <Proposta href='#o-que-move-a-match' onClick={onClose}><Texto>O que move a match</Texto></Proposta>
        </>
        :
        <>
          <LinkOqentregamos href={`${origin}/#o-que-entregamos`}><Texto>O que entregamos</Texto></LinkOqentregamos>
          <LinkProposta href={`${origin}/#o-que-move-a-match`}><Texto>O que move a match</Texto></LinkProposta>
        </>
      }

      <FaleCaGente onClick={handleModalContact}><Texto>Fale com a gente</Texto></FaleCaGente>
      <Trabalhar href="https://match-mt.gupy.io/" target="_blank" rel="noreferrer"><TextoT>Quero trabalhar na match</TextoT></Trabalhar>
      <Endereco>
        <text>Rua João Moura, 1144 – São Paulo/SP</text>
        <br />
        <text>+55 11 3097-1057</text>
        <a href="https://www.linkedin.com/company/match-marketing-tech/" target="_blank" rel="noreferrer"><Linkedin src={LinkedinSVG} /></a>
      </Endereco>
    </Container>
  );
};

export default Menu;
