import { useState } from "react";

import AnchorLink from "react-anchor-link-smooth-scroll";

import { useProposta } from "../../contexts/proposta";

import { MajorCircle, Textwhitewhat, Background, AncoraWhy, AncoraHow, AncoraWhat, MidCircle, Whytext, PizzaWrapper, PizzaWrapperTwo, PizzaWrapperthree, TinyCircle, Button, Title, Descricao, Howtext, Whattext, Linha, Blue, BackgroundBottomGradient, BackgroundTwo, BackgroundBottomGradientTwo, Textwhitewow, Textwhitewhy } from "./styles"


const PizzaMobile = () => {
  const [circleFocusTiny, setCircleTinyFocus] = useState(true)
  const [circleFocusMid, setCircleMidFocus] = useState(false)
  const [circleFocusMajor, setCircleMajorFocus] = useState(false)

  const { handleProposta } = useProposta();

  const handleFocusCircleMid = () => {
    setCircleTinyFocus(false)
    setCircleMidFocus(true)
    setCircleMajorFocus(false)
    handleProposta({ value: 2 })

  }

  const handleFocusCircleTiny = () => {
    setCircleTinyFocus(true)
    setCircleMidFocus(false)
    setCircleMajorFocus(false)
    handleProposta({ value: 3 })
  }
  const handleFocusCircleMajor = () => {
    setCircleTinyFocus(false)
    setCircleMidFocus(false)
    setCircleMajorFocus(true)
    handleProposta({ value: 1 })
  }
  return (
    <>
      <AncoraWhy id='Pizzawhy'></AncoraWhy>
      <Background>
        <BackgroundBottomGradient>
          <Title>O que move a <Blue> match</Blue></Title>
          <PizzaWrapper>
            <MajorCircle className={"inativo"}>
              <AnchorLink href='#Pizzawhat'><Button onClick={(e) => handleFocusCircleMajor()}>
                <Whattext> What?</Whattext>
              </Button></AnchorLink>
              <MidCircle className={"inativo"}>
                <AnchorLink href='#Pizzahow'><Button onClick={(e) => handleFocusCircleMid()}>
                  <Howtext>How?</Howtext>
                </Button></AnchorLink>
                <TinyCircle className={"ativo"}>
                  <Textwhitewhy>Why?</Textwhitewhy>
                </TinyCircle>
              </MidCircle>
            </MajorCircle>
          </PizzaWrapper>
          <Linha className="tiny tiny-rotate" />
          <Title>Por que estamos aqui</Title>
          <Descricao>Existimos para construir relações a longo prazo, conectando pessoas, dados e tecnologia.</Descricao>
          <AncoraHow id='Pizzahow'></AncoraHow>
          <PizzaWrapperTwo>
            <MajorCircle
              className={"inativo"} >
              <AnchorLink href='#Pizzawhat'><Button onClick={(e) => handleFocusCircleMajor()}>
                <Whattext> What?</Whattext>
              </Button></AnchorLink>
              <MidCircle className={"ativo"}>
                <Textwhitewow>How?</Textwhitewow>
                <TinyCircle className={"inativo"}>
                  <AnchorLink href='#Pizzawhy'><Button onClick={(e) => handleFocusCircleTiny()}>
                    <Whytext>Why?</Whytext>
                  </Button></AnchorLink>
                </TinyCircle>
              </MidCircle>
            </MajorCircle>
          </PizzaWrapperTwo>
          <Linha className="mid mid-rotate" />
          <Title>O que fazemos</Title>
          <Descricao>Conduzimos projetos de implementação, consultoria e operação de estratégias de relacionamento, plataformas de experiência digital e ferramentas de Marketing Cloud com base em estudos estatísticos e ciência de dados.</Descricao>
          </BackgroundBottomGradient>
          </Background>
          <BackgroundBottomGradientTwo>
          <BackgroundTwo>
          <AncoraWhat id='Pizzawhat'></AncoraWhat>
          <PizzaWrapperthree >
            <MajorCircle
              className={"ativo"}>
              <Textwhitewhat>What?</Textwhitewhat>
              <MidCircle className={"inativo"}>
                <AnchorLink href='#Pizzahow'><Button onClick={(e) => handleFocusCircleMid()}>
                  <Howtext>How?</Howtext>
                </Button></AnchorLink>
                <TinyCircle className={"inativo"}>
                  <AnchorLink href='#Pizzawhy'><Button onClick={(e) => handleFocusCircleTiny()}>
                    <Whytext>Why?</Whytext>
                  </Button></AnchorLink>
                </TinyCircle>
              </MidCircle>
            </MajorCircle>
          </PizzaWrapperthree>
          <Linha className="major major-rotate" />
      <Title>Como fazemos</Title>
      <Descricao>Trabalhamos em parceria com o seu negócio, oferecendo soluções e projetos de melhoria a partir de ferramentas de marketing digital, dados e tecnologia.</Descricao>
      </BackgroundTwo>
      </BackgroundBottomGradientTwo>
    </>
  )
}

export default PizzaMobile;