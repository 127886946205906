import { useState } from "react";
import { useProposta } from "../../contexts/proposta";
import { MajorCircle, MidCircle, PizzaWrapper, TinyCircle, Text, Button } from './styles'

const Pizza = () => {
  const [circleFocusTiny, setCircleTinyFocus] = useState(true)
  const [circleFocusMid, setCircleMidFocus] = useState(false)
  const [circleFocusMajor, setCircleMajorFocus] = useState(false)

  const { handleProposta } = useProposta();

  const handleFocusCircleMid = () => {
    setCircleTinyFocus(false)
    setCircleMidFocus(true)
    setCircleMajorFocus(false)
    handleProposta({ value: 2 })

  }

  const handleFocusCircleTiny = () => {
    setCircleTinyFocus(true)
    setCircleMidFocus(false)
    setCircleMajorFocus(false)
    handleProposta({ value: 3 })
  }
  const handleFocusCircleMajor = () => {
    setCircleTinyFocus(false)
    setCircleMidFocus(false)
    setCircleMajorFocus(true)
    handleProposta({ value: 1 })
  }
  return (
    <PizzaWrapper >
      <MajorCircle
        className={circleFocusMajor ? "ativo" : "inativo"}>
        <Button onClick={(e) => handleFocusCircleMajor()}>
          <Text className={circleFocusMajor ? "darker-major" : "light-major"}>What?</Text>
        </Button>
        <MidCircle className={circleFocusMid ? "ativo" : "inativo"}>
          <Button onClick={(e) => handleFocusCircleMid()}>
            <Text className={circleFocusMid ? "darker-mid" : "light-mid"}>How?</Text>
          </Button>
          <TinyCircle className={circleFocusTiny ? "ativo" : "inativo"}>
            <Button onClick={(e) => handleFocusCircleTiny()}>
              <Text className={circleFocusTiny ? "darker-tiny" : "light-tiny"} >Why?</Text>
            </Button>
          </TinyCircle>
        </MidCircle>
      </MajorCircle>
    </PizzaWrapper>
  )
}

export default Pizza;