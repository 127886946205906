import styled from 'styled-components'

export const ContainerButton = styled.div`
    display: grid;
    height: 46px;
    justify-content: right;
    margin-top: 32px;
    margin: 0 auto;
    max-width: 872px;
`

export const Button = styled.button`    
    background-color: transparent;
    border: none;
    color: #000;
    cursor: pointer;
    font-size: 20px;
    font-weight: 700;
    height: 30px;
    margin: 8px;
    padding: 7px;
    text-decoration: none;
    transition: all 0.1s ease-out;
    width: 60px;    

    &:hover {
        font-size: 18px;
        height: 25px;
        margin-right: 8px;
        padding-right: 3px;
    }

    @media (max-width: 480px) {
        display: none;
    }
`