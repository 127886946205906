import PostDescription from './PostDescription'
import * as S from './styles'

export default function Post({ content }) {
  return (
    <S.Wrapper>
      <S.Title>{content.title}</S.Title>
      <S.SubTitle>{content.sub_title}</S.SubTitle>        
      <S.InfoWrapper>
        <span>{content.last_update}</span>
        <span>{`Tempo de leitura: ${content.reading_time}`}</span>
        {content.about_the_author && (
          <span>{`Escrito por: ${content.about_the_author.author}`}</span>
        )}
      </S.InfoWrapper>
      <PostDescription content={content.description}></PostDescription>
      <S.Tags>
        {'Tags: '}
        {content.tags.map((tag, index) => {
          const suffix = index + 1 !== content.tags.length ? ', ' : ''
          const editedTag = tag + suffix
          return <span key={index}>{editedTag}</span>
        })}
      </S.Tags>
      <S.SocialNetworks>
        {content.social_networks.map((element) => (
          <div key={element.id}>
            <a href={element.href} target='_blank' rel='noreferrer'>
              <S.IcoSocialNetworks src={element.ico} alt={element.name} />
            </a>
          </div>
        ))}
      </S.SocialNetworks>
    </S.Wrapper>
  )
}
