import { useEffect, useRef } from 'react'
import { useField } from '@unform/core'

import * as S from './styles'

interface Props {
  name: string
  label?: string
}

type InputProps = JSX.IntrinsicElements['input'] & Props

export default function Checkbox({ name, label, placeholder, ...rest }: InputProps) {
  const inputRef = useRef<HTMLInputElement>(null)

  const { fieldName, defaultValue, registerField, error } = useField(name)

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef,
      getValue: ref => {
        return ref.current.value
      },
      setValue: (ref, value) => {
        ref.current.value = value
      },
      clearValue: ref => {
        ref.current.value = ''
      },
    })
  }, [fieldName, registerField])

  return (
    <S.Wrapper>
      <S.InputWrapper>
        {label && <label htmlFor={fieldName}>{label}</label>}

        <input
          type="checkbox"
          id={fieldName}
          ref={inputRef}
          defaultValue={defaultValue}
          {...rest}
        />

        {error && <span>{error}</span>}
      </S.InputWrapper>
    </S.Wrapper>
  )
}