import axios from "axios";

const localHost = ["127.0.0.1", "localhost"];
const isLocal = !!~localHost.indexOf(window.location.hostname);

const local = "http://localhost";
const prod = "insira um host de prod";

const host = isLocal
  ? local
  : prod;

const api = axios.create({
  baseURL: host,
});

export default api;
