import styled from "styled-components";
import * as RS from 'react-select'

export const Error = styled.span`
  height: 20px;

  span {
    color: #c53030;

    &::before {
      border-color: #c53030 transparent;
    }
  }
`;

export const ReactSelect = styled(RS.default)`
 &::placeholder{
   font-weight: 100;
 }
`

export const SelectWrapper = styled.section`
  display: flex;
  flex-direction: column;

  width: 100%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-appearance: none;
  -moz-appearance: none;

  appearance: none;
  border: none;

  .react-select__control--menu-is-open {
    border: none;
    svg {
    transform: rotate(180deg);
      }
  }

  .react-select__control--is-focused {
    border: none;
    box-shadow: none;
  }

  .react-select__indicator-separator{
    display: none;
  }

  .react-select__menu {
    border: none;
    box-shadow: none;
    background-color: black;
    color: #f1f1f1;
  }

  .css-1pahdxg-control{
    border: none;
  }
  .css-1pahdxg-control:hover{
    border-color: transparent;
  }
  input:focus{
    outline: none;
    color: black;
    }

    svg {
     color: #111;
    }

    &:focus{
     transform: rotate(180deg);
    }
  .react-select__control{

    margin: 0;      
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-appearance: none;
  -moz-appearance: none;

  appearance: none;
  border: none;


  }

  .react-select__control::placeholder{
   font-weight: 100;
 }

  > label {
  margin-top: 0.5rem;
  font-size: 0.9rem;
  }

`;
