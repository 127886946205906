import { Link } from 'react-router-dom'
import * as S from './styles'

export default function PostCard({ content }) {
  const scrollToTop = () =>{
    window.scrollTo({
      top: 0, 
      behavior: 'auto'
    });
  };

  return (
    <S.CardWrapper>
      <S.Border>
        <S.Image src={content.image} alt={content.title} />
        <S.TextWrapper>
          <S.Title>{content.title}</S.Title>
          <S.Excerpt>{content.description}</S.Excerpt>
          <S.ButtonWrapper>
            <Link to={content.slug}>
              <S.Button onClick={scrollToTop}>Ler conteúdo completo</S.Button>
            </Link>
          </S.ButtonWrapper>
        </S.TextWrapper>
      </S.Border>
    </S.CardWrapper>
  )
}
