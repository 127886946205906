import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  justify-content: space-between;

  width:100%;
  height:700px;

  background: #222;

  @media (max-width: 480px) {
    display: none;
    }
`
export const Mobile = styled.div`
  display: none;
  margin-top: -1px;
  background: black;
  height: 1800px;
  flex-direction: column;
  align-items: center;
  align-content: center;
  justify-content: space-between;

  @media (min-width: 480px) and (max-width: 1440px) {
    display: none;
    }
    @media (max-width: 480px) {
    display: block;
    }
`;



export const PropostaWrapper = styled.div`
  display: flex;
  /* flex-direction: column; */
  align-items: center;
  align-content: center;
  justify-content: center;

  width:100%;
  height:700px;

  background: black;
`

export const Text = styled.h2`
  color: #f1f1f1;
  left: 318px;
  width: 523px;
  height: 34px;
  font-size: 28px;
  font-weight: 500;
  position: absolute ;
  margin-bottom: 1rem;

  @media (min-width: 480px) and (max-width: 1440px) {
    left: 41px;
    width: 523px;
    height: 34px;
    }
    @media (max-width: 480px) {
      left: 16px;
      width: 328px;
      height: 34px;
    }
`
export const Background = styled.img`
  top: -100px;
  width: 100vw;
  height: 960px;
  margin-left: -25px;
  position: absolute;
  z-index: -0;

	mask-image: linear-gradient(to bottom, transparent 4%, black 14%);

  @media (min-width: 480px) and (max-width: 1440px) {
    mask-image: linear-gradient(to top, black 1%, transparent 110%);
    top: -30%;
    height: 1060px;
    }
`;
export const BackgroundGradient = styled.div`
  top: -100px;
  width: 100vw;
  height: 960px;
  margin-left: -25px;
  position: absolute;
  z-index: -0;

	background:  linear-gradient(to bottom, transparent 69%, black 80%);

  @media (min-width: 480px) and (max-width: 1440px) {
    top: -200px;
    height: 1060px;
    }
`;

export const Blue = styled.text`
    color: white;
    font-weight: 600;
`;
