import styled from 'styled-components';

import LogoSVG from '../../assets/SVG/logo-match.svg'

export const Container = styled.div``;

export const ButtonMenu = styled.div`
  cursor: pointer;
  display: inline-block;
  position: absolute;
  top: 36px;
  left: 1483px;
  z-index: 99;

  @media (min-width: 480px) and (max-width: 1440px) {
    left: 1206px;
    }
  @media (max-width: 480px) {
    left: 320px;
    }

.bar4{
  width: 24px;
  height: 2px;
  margin-left: 50%;
  transform: translateX(-50%);
}

.bar5{
  width: 18px;
  height: 2px;
  margin-left: 50%;
  transform: translateX(-50%); }

.bar6{
  width: 24px;
  height: 2px;

  margin-left: 50%;
  transform: translateX(-50%);}

:hover{

.bar6{
  width: 18px;
  height: 2px;
  background-color: blue;

  @media (max-width: 480px) {
    width: 24px;
    height: 2px;
    background-color: black;
  }
}

.bar4{
  width: 18px;
  height: 2px;
  background-color: blue;

  @media (max-width: 480px) {
    width: 24px;
    height: 2px;
    background-color: black;

  }
}
.bar5{
  width: 24px;
  height: 2px;
  background-color: blue;
  @media (max-width: 480px) {
    width: 18px;
    height: 2px;
    background-color: black;
  }
}
}

.bar3 {
  width: 24px;
  height: 1px;
  -webkit-transform: rotate(45deg) translate(-12px, -12px) ;
  transform: rotate(45deg) translate(-12px, -12px);
}

.bar2 {opacity: 0;}

.bar1{
  width: 24px;
  height: 1px;
  -webkit-transform: rotate(-45deg) translate(-7px, 6px);
  transform: rotate(-45deg) translate(-7px, 6px);
}

`;

export const Bar = styled.div`
  width: 14px;
  height: 14px;
  background-color: #333;
  margin: 6px 0;
  transition: 0.4s;
`

export const Logo = styled.img`
    margin-top: 2rem;
    margin-left: 413px;
    width: 157px;
    height: 24px;
    background: url(${LogoSVG}) ;
    opacity: 1;

    @media (min-width: 480px) and (max-width: 1440px) {
        margin-top: 32px;
        margin-left: 136px;
        width: 157px;
        height: 24px;

    }
    @media (max-width: 480px) {
        margin-top: 32px;
        margin-left: 16px;
        width: 157px;
        height: 24px;
    }
`;

export const Branco = styled.div`
  background: white;
  width: 100vw;
  height: 88px;
  opacity: 80%;
`;

export const TopHeader = styled.header`
    display: flex;
    justify-content: space-between;
    align-content: center;
    align-items: center;
    width: 100vw;
    height: 88px;
    position: fixed;
    top: 0px;
    z-index: 95;
`;

export const Divlogo = styled.div`
  display: flex;
  justify-content: space-between;
  align-content: center;
  align-items: center;
  position: fixed;
  top: 0px;
  z-index: 96;
`;

export const Blur = styled.div`
  position: absolute;
  width: 100vw;
  height: 88px;
  opacity: 85%;
  background: transparent;
  backdrop-filter: blur(15px);
  -webkit-backdrop-filter: blur(15px);
`;

export const Menudiv = styled.div`
    margin-top: -32px;
    margin-left: 1486px;
    width: 40px;
    height: 40px;
    background: black;

    @media (min-width: 480px) and (max-width: 1440px) {
    margin-top: 36px;
    margin-left: 1206px;

}
    @media (max-width: 480px) {
        margin-top: 36px;
        left: 320px;
    }
`;

