import styled from 'styled-components';

export const Container = styled.div `
    color: blue;
    padding-top: 88px;
    height: calc(100vh - 238px); //88 Header + 150 footer = 238px 
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    letter-spacing: 1.2px;
    gap: 20px;
`
export const Button = styled.button `
    text-decoration: none;
    padding: 7px;
    font-size: 21px;
    background-color: transparent;
    border: none;
    cursor: pointer;
    &:hover {
        color: red;
    }
`
