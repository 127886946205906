import styled from 'styled-components';

export const Container = styled.div`
    height: 70vh;

    @media (min-width: 480px) and (max-width: 1440px) {
        height: 70vh;
    }

    @media (max-width: 480px) {
        height: 70vh;
    }
    @media (max-width: 380px) {
        height: 33rem;
    }

    @media (max-width: 360px) {
        height: 80vh;
    }
`;

export const Blue = styled.text`
    color: #0000f2;
`;

export const Apresentaçao = styled.div`
    margin-top: 228px;
    margin-left: 413px;
    width: 687px;
    height: 174px;
    text-align: left;
    font-size: 48px;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
    font-weight: 400;

    @media (min-width: 480px) and (max-width: 1440px) {
        margin-top: 160px;
        margin-left: 136px;
        width: 687px;
        height: 174px;
        text-align: left;
        font-size: 48px;
        letter-spacing: 0px;
        color: #000000;
        opacity: 1;

    }
    @media (max-width: 480px) {
        margin-top: 104px;
        margin-left: 16px;
        width: 328px;
        height: 230px;
        text-align: left;
        font-size: 38px;
        letter-spacing: 0px;
        color: #000000;
        opacity: 1;
    }
`;

export const Descriçao = styled.div`
    margin-top: 30px;
    margin-left: 445px;
    width: 500px;
    height: 91px;
    text-align: left;
    font-size: 16px;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
    line-height: 24px;
    font-weight: 300;

    @media (min-width: 480px) and (max-width: 1440px) {
        margin-top: 30px;
        margin-left: 168px;
        width: 500px;
        height: 91px;
    }
    @media (max-width: 480px) {
        margin-top: -0.5rem;
        margin-left: 32px;
        margin-bottom: 30px ;
        width: 296px;
        height: 91px;
    }
`;

export const Divbuttons = styled.div`
    display: flex;
    width: 396px;
    height: 81px;
    margin-left: 445px;

    @media (min-width: 480px) and (max-width: 1440px) {
        margin-left: 168px;
        margin-top: 20px;
    }
    @media (max-width: 480px) {
        display: block;
        margin-left: 32px;
        width: 188px;
    }

`;


export const Butaoum = styled.div`
    margin: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 188px;
    height: 81px;

    .ButtonMaisDetalhes{
        width: 188px;
        height: 51px;
        background: black;
        color: white;
        border: none;
        font-size: 16px;
        transition: all 0.1s ease-out;
    }
    .ButtonMaisDetalhes:hover{
        width: 178px;
        height: 41px;
    }
`;


export const Butaodois = styled.div`
    margin: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 188px;
    height: 81px;
    margin-left: 5px;

    @media (max-width: 480px) {
        margin-left: 0px;
        margin-top: -16px;
    }

    .ButtonContato{
        width: 188px;
        height: 51px;
        background: #5cf200;
        color: black;
        border: none;
        font-size: 16px;
        transition: all 0.1s ease-out;
    }
    .ButtonContato:hover{
        width: 176px;
        height: 41px;
    }
`;

export const Imagemum = styled.img`
    position: absolute;
    top: -50px;
    left: 1132px;
    width: 150px;
    height: 300px;

    @media (min-width: 480px) and (max-width: 1440px) {
        top: -50px;
        left: 855px;
        width: 150px;
        height: 300px;
    }
    @media (max-width: 480px) {
        top: 200px;
        left: 270px;
        width: 32px;
        height: 64px;
    }
`;

export const Imagemdois = styled.img`
    position: absolute;
    top: 25px;
    left: 1357px;
    width: 150px;
    height: 300px;

    @media (min-width: 480px) and (max-width: 1440px) {
        top: 25px;
        left: 1080px;
        width: 150px;
        height: 300px;
    }
    @media (max-width: 480px) {
        top: 280px;
        left: 302px;
        width: 32px;
        height: 64px;
    }
`;

export const Imagemtres = styled.img`
    position: absolute;
    top: 250px;
    left: 1207px;
    width: 225px;
    height: 150px;

    @media (min-width: 480px) and (max-width: 1440px) {
        top: 250px;
        left: 930px;
        width: 225px;
        height: 150px;
    }
    @media (max-width: 480px) {
        top: 390px;
        left: 260px;
        width: 48px;
        height: 32px;
    }

`;
