import styled from 'styled-components'

const padding = '16px'

export const CardWrapper = styled.div`
  max-width: 428px;
  @media screen and (min-width: 1024px) {
    max-width: none;
  }
`
export const Border = styled.div`
  border: 1px solid #707070;
`
export const TextWrapper = styled.div`
  padding: ${padding};
  width: calc(100% - 2px - (2 * padding));
`
export const Image = styled.img`
  height: 186px;
  object-fit: cover;
  width: 100%;
`
export const Title = styled.h2`
  font-size: 28px;
  font-weight: bold;
`
export const Excerpt = styled.p`
  font-size: 20px;
  font-weight: 300;
  margin: ${padding} 0;
`
export const ButtonWrapper = styled.div`
  align-items: center;
  display: flex;
  height: 50px;
  justify-content: center;
  width: 255px;
`
export const Button = styled.button`
  background-color: #5CF200;
  border: none;
  color: black;
  cursor: pointer;
  font-size: 16px;
  height: 50px;
  transition: all 0.1s ease-out;
  width: 255px;
  &:hover {
    height: 41px;
    width: 218px;
  }
`
