import styled from 'styled-components'

export const PizzaWrapper = styled.div`
display: flex;
align-items: center;
align-content: center;
justify-content: center;
z-index: 90;
margin-left: 412px;
width: 542px;
height: 508px;

.ativo {
    background: #f1f2f1;
  }
  .inativo {
    background: black;
  }

  @media (min-width: 480px) and (max-width: 1440px) {
    margin-left: 135px;
    }
    @media (max-width: 480px) {
    margin-left: 32px;
    width: 300px;
    height: 300px;
    }
`

export const MajorCircle = styled.div`
  display: flex;
  flex-direction: column;

  align-items: center;
  align-content: center;
  justify-content: center;
  transition: all 0.3s ease-out;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: black;

  border: solid 0.13rem #555;

button {
  width: 31rem;
  height: 31rem;
  cursor: pointer;
  border-radius: 9999px;
  background: transparent;
  font-size: 1.2rem;
  font-weight: 400;
  position: absolute;
  border: none;
  
  .light-major {
    color: #f1f1f1;
    margin-bottom: 24rem;
  }
  .darker-major {
    color: gray;
    margin-bottom: 24rem;
  }
}
`
export const MidCircle = styled.div`
  display: flex;
  flex-direction: column;

  align-items: center;
  align-content: center;
  justify-content: center;

  width: 60%;
  height: 60%;
  border-radius: 50%;
  transition: all 0.3s ease-out;
  
  
  border: solid 0.13rem #555;
  .ativo {
    background: #f1f2f1;
    }
    
  .inativo {
    background: black;
  }

button {
  color: gray;
  width: 19rem;
  height: 19rem;
  border-radius: 9999px;
  background: transparent;
  font-size: 1.2rem;
  font-weight: 400;
  position: absolute;
  border: none;

  .light-mid {
    color: #f1f1f1;
    margin-bottom: 12rem;
  }
  .darker-mid {
    color: gray;
    margin-bottom: 12rem;
  }
}
`
export const TinyCircle = styled.div`
  display: flex;
  flex-direction: column;

  align-items: center;
  align-content: center;
  justify-content: center;
  transition: all 0.3s ease-out;
  width: 40%;
  height: 40%;
  border-radius: 50%;
  border: solid 0.13rem #555;

  .ativo {
 background: #f1f2f1;
}
.inativo {
 background: black;
}

button {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: transparent;
  font-size: 1.2rem;
  font-weight: 400;
  position: relative;
  border: none;

  .light-tiny {
    color: #f1f1f1;
    
  }
  .darker-tiny {
    color: gray;
    
  }
}
`

export const Text = styled.h2`
  font-size: 1.2rem;
  font-weight: 400;
`

export const Button = styled.button`
`