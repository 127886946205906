import styled from 'styled-components'
import img from '../../assets/SVG/bg.svg'


export const PizzaWrapperthree = styled.div`
    margin-top: 2rem;
    display: flex;
    margin-bottom: 70px;
    align-items: center;
    align-content: center;
    justify-content: center;
    margin-left: 50%;
    transform: translateX(-50%);
    width: 296px;
    height: 296px;

    .ativo {
        background: #f1f2f1;
    }
    .inativo {
      background: black;
    }
`;

export const AncoraWhy = styled.div`
    top: -100px;
    position: absolute;
`;

export const AncoraHow = styled.div`
    top: 31.5rem;
    position: absolute;
`;

export const AncoraWhat = styled.div`
    top: 65rem;
    position: absolute;
`;

export const PizzaWrapperTwo = styled.div`
    margin-top: 5rem;
    display: flex;
    margin-bottom: 50px;
    align-items: center;
    align-content: center;
    justify-content: center;
    margin-left: 50%;
    transform: translateX(-50%);
    width: 296px;
    height: 296px;

    .ativo {
        background: #f1f2f1;
    }
    .inativo {
      background: black;
    }
`;

export const PizzaWrapper = styled.div`
margin-top: 30px;
margin-bottom: 50px;
display: flex;
align-items: center;
align-content: center;
justify-content: center;
margin-left: 50%;
transform: translateX(-50%);
width: 296px;
height: 296px;

.ativo {
    background: #f1f2f1;
  }
  .inativo {
    background: black;
  }
`

export const MajorCircle = styled.div`
   margin-top: 5px;
  display: flex;
  flex-direction: column;

  align-items: center;
  align-content: center;
  justify-content: center;

  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: black;

  border: solid 0.5px gray;

button {
  width: 19rem;
  height: 19rem;
  border-radius: 9999px;
  background: transparent;
  font-size: 1.2rem;
  font-weight: 400;
  position: absolute;
  border: none;
  margin-left: -50%;
  margin-top: -20%;
  .light-major {
    color: #f1f1f1;
    margin-bottom: 24rem;
  }
  .darker-major {
    color: gray;
    margin-bottom: 24rem;
  }
}
`
export const MidCircle = styled.div`
  display: flex;
  flex-direction: column;

  align-items: center;
  align-content: center;
  justify-content: center;

  width: 60%;
  height: 60%;
  border-radius: 50%;
  
  
  border: solid 0.5px gray;
  .ativo {
 background: #f1f2f1;
}
.inativo {
 background: black;
}
z-index: 3;

button {
  color: gray;
  width: 11rem;
  height: 11rem;
  border-radius: 9999px;
  background: transparent;
  font-size: 1.2rem;
  font-weight: 400;
  position: absolute;
  border: none;
  margin-left: -30%;
  margin-top: -53px;

  .light-mid {
    color: #f1f1f1;
    margin-bottom: 12rem;
    
  }
  .darker-mid {
    color: gray;
    margin-bottom: 15rem;
  }
}
`
export const TinyCircle = styled.div`
  display: flex;
  flex-direction: column;

  align-items: center;
  align-content: center;
  justify-content: center;
  width: 40%;
  height: 40%;
  border-radius: 50%;
  z-index: 5;
  border: solid 0.5px gray;

  .ativo {
 background: #f1f2f1;
}
.inativo {
 background: black;
}

button {
  width: 5rem;
  height: 5rem;
  border-radius: 50%;
  background: transparent;
  font-size: 1.2rem;
  font-weight: 400;
  position: relative;
  border: none;
  margin-left: 1px;
  margin-top: -5px;

  .light-tiny {
    color: #f1f1f1;
    
  }
  .darker-tiny {
    color: gray;
 
  }
}
`

export const Textwhitewow = styled.h2`
    font-size: 1.2rem;
    font-weight: 600;
    text-decoration: none;
    margin-top: -1.5rem;
    margin-bottom: 0.8rem;
`;

export const Textwhitewhat = styled.h2`
    font-size: 1.2rem;
    font-weight: 600;
    text-decoration: none;

    margin-top: -1.5rem;
    margin-bottom: 1.1rem;
`;

export const Textwhitewhy = styled.h2`
   font-size: 1.2rem;
    font-weight: 600;
    text-decoration: none;

    margin-top: -1.5rem;
    margin-bottom: -1.5rem;
`;

export const Button = styled.button`
`

export const Whytext = styled.h2`
    font-size: 1.2rem;
    font-weight: 600;
    text-decoration: none;
    color: #f1f1f1;
    margin-top: 0px;

`;

export const Howtext = styled.h2`
    font-size: 1.2rem;
    font-weight: 600;
    text-decoration: none;
    color: #f1f1f1;
    margin-top: -70px;
`;

export const Whattext = styled.h2`
    font-size: 1.2rem;
    font-weight: 600;
    text-decoration: none;
    color: #f1f1f1;
    margin-top: -130px;
`;


export const Title = styled.text`
  margin-left: 1.8rem;
  width: 328px;
  height: 34px;
  font-weight: 600;
  text-align: left;
  font-size:28px ;
  letter-spacing: 0px;
  color: #FFFFFF;
  opacity: 1;
`;

export const Descricao = styled.p`
  color: #f1f1f1;
  font-size: 16px;
  font-weight: 300;
  line-height: 24px;
  text-align: left;
  margin-top: 1rem;
  margin-bottom: 1rem;
  margin-left: 1.8rem;
  width: 90vw;
`

export const Blue = styled.text`
     color: white;
    font-weight: 600;
`;

export const Linha = styled.div` 
`;

export const Background = styled.div`
  width: 100vw;
  height: 66rem;
  z-index: -3;
  background: repeat-y url(${img});
  display: flex;
  flex-direction: column;
  /* align-items: center;  */

  .tiny{
    border: 1px solid gray;
    background: gray;
    height: 0.05rem;
    position: relative;
    z-index: 5;
    top: -4.8rem;
    margin: 0 auto;
    transform: translateX(-50%);
  }
  .tiny-rotate{
    transform: rotate(90deg);
    width: 9rem;
  }
  .mid{
    border: 1px solid gray;
    background: gray;
    height: 0.05rem;
    position: relative;
    z-index: 5;
    top: -3rem;
    margin: 0 auto;
    transform: translateX(-50%);
    width: 6.2rem;
  }
  .mid-rotate{
    transform: rotate(90deg);
  }

`;

export const BackgroundBottomGradient = styled.div`
  width: 100vw;
  height: 94rem;
  
  background: linear-gradient(0.5turn,black, transparent, transparent, #f1f1f110 10%, transparent, transparent, black);
  
`;

export const BackgroundTwo = styled.div`
  margin-top: 3rem;
  width: 100vw;
  height: 40rem;
  z-index: -3;
  background: url(${img});
  display: flex;
  flex-direction: column;
  /* align-items: center;  */

  .major{ 
    border: 1px solid gray;
    background: gray;
    height: 0.05rem;
    position: relative;
    z-index: 5;
    top: -1.5rem;
    margin: 0 auto;
    transform: translateX(-50%);

    width: 4rem;
  }
  .major-rotate{
    transform: rotate(90deg);
    width: 4rem;
    top: -1.9rem;
    };
`;

export const BackgroundBottomGradientTwo = styled.div`
  width: 100vw;
  height: 45rem;

  mask-image: linear-gradient(to top, transparent 10%, black 35%);
`;