import styled from 'styled-components';

import { AiOutlineClose } from "react-icons/ai";

export const Container = styled.div`
        background: black;
        height: 450px;
        width: 100%;
        margin-top: -1px;
        padding: 0;
    
        @media (max-width: 480px) {
            display: block;
            height: 630px;
    }
`;

export const DescriptionFourth = styled.div`
    margin-left: 413px;
    width: 555px;
    height: 174px;
    font-weight: 400;
    .Textofinal{ 
        text-align: left;
        font-size: 48px;
        letter-spacing: 0px;
        color: #FFFFFF;
        opacity: 1;

        @media (max-width: 480px) {
            font-size: 38px;
    }
    }

    @media (min-width: 480px) and (max-width: 1440px) {
        margin-left: 136px;
        width: 555px;
        height: 174px;
    }

    @media (max-width: 480px) {
        margin-left: 1.8rem;
        margin-bottom: 20% ;
        width: 90%;
        height: 230px;
    }
`;

export const Video = styled.div`
    position: absolute;
    top: 0.8rem;
    left: 1132px;
    width: 375px;
    height: 211px;
    border-radius: 10px;
    

    @media (min-width: 480px) and (max-width: 1440px) {
        left: 855px; 
    }

    @media (max-width: 480px) {
        top: 50%;
        left: 50%;
        transform: translateX(-50%);
        width: 328px;
        height: 184px;
    }
`;

export const Player = styled.source`
    width: 500px;
    height: 500px;
`

export const Telavideo = styled.img`
    width: 375px;
    height: 211px;
    border-radius: 10px;

    @media (max-width: 480px) {
    width: 328px;
    height: 184px;
    }
`;


export const ConteinerModal = styled.div`
    height: 100vh;
    width: 100vw;
    left: 0;
    z-index: 10;
    display: flex;
    justify-content: center; 
    align-items: center ;
    align-content: center;
    background: white;
    z-index: 99;
    top: 0;
    position: fixed;

    @media (max-width: 480px) {  
        width: 100%;
        top: 0;
        height: 250vw;
        align-items: center;
        justify-content: center;
    }
`;

export const Closeicon = styled(AiOutlineClose)`
    width: 30px;
    height: 30px;
    color: black;
    top: 50px;
    left: 1483px;
    position: absolute;

    @media (min-width: 480px) and (max-width: 1440px) {
        top: 10px;
        left: 1206px;
        width: 25px;
        height: 25px;
    }

    @media (max-width: 480px) {
        left: 75%;
        top: 4%;
        width: 25px;
        height: 25px; 
        position: absolute;
    }
`;

export const Control = styled.video`
width: 60rem;
height: 34rem;


@media (max-width: 480px) {
    width: 17rem;
    height: 8.5rem;
    margin-top: -50%;

}

`

export const Source = styled.source`
`

export const Visual = styled.text`
    text-align: left;
    font-size: 20px;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 1;
    margin-left: 4px;
    width: 687px;
    height: 174px;
    font-weight: 400;
    position: relative;
    top: 1rem;

    @media (max-width: 480px) {
        width: 100%;
        margin-left: 1px;
        margin-bottom: 20% ;
        height: 230px;
        font-size: 20px;
}
`;


export const Blue = styled.text`
    color: white;
    font-weight: 600;
`;