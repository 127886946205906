import { useState, useEffect } from "react";
import { DisclaimerWrapper, Text, Title, Linha } from "./styles"



const Disclaimer = (disclaimer: any) => {
  const [text, setText] = useState("");
  const [width, setWidth] = useState("");
  const [title, setTitle] = useState("");

  useEffect(() => {
    switch (disclaimer['disclaimer']?.value) {

      case 1:
        setText('Conduzimos projetos de implementação, consultoria e operação de estratégias de relacionamento, plataformas de experiência digital e ferramentas de Marketing Cloud com base em estudos estatísticos e ciência de dados.')
        setTitle('Como fazemos')
        setWidth("major")
        break
      case 2:
        setText('Trabalhamos em parceria com o seu negócio, oferecendo soluções e projetos de melhoria a partir de ferramentas de marketing digital, dados e tecnologia.')
        setTitle('O que fazemos')
        setWidth("mid")
        break
      case 3:
        setText('Existimos para construir relações a longo prazo, conectando pessoas, dados e tecnologia.')
        setTitle('Por que estamos aqui')
        setWidth("tiny")
        break
      default:
        setText('Existimos para construir relações a longo prazo, conectando pessoas, dados e tecnologia.')
        setTitle('Por que estamos aqui')
        setWidth("tiny")
        break;
    }


  }, [disclaimer])

  return (
    <DisclaimerWrapper>
      <Linha className={width} />
      <Title>{title}</Title>
      <Text>{text}</Text>
    </DisclaimerWrapper>
  )
}

export default Disclaimer;