import styled from 'styled-components';

export const Container = styled.div`
    background: black;
    height: 720px;
    width: 100%;
    
    @media (max-width: 480px) {
        height: 1300px;
        width: 100%;
    }
`;

export const Top = styled.div` 
    width: 100%;
    height: 18px;
    @media (max-width: 480px) {
        margin: -5px;
        width: 101.3%;
        height: 32px;
    }
`;

export const ContainerFourth = styled.div`
    display: flex;
`;

export const Titulopagina = styled.h1`
    margin-top: 160px;
    margin-left: 20rem;
    width: 285px;
    height: 34px;
    color: white;
    font-weight: 500;

    @media (min-width: 480px) and (max-width: 1440px) {
        margin-top: 160px;
        margin-left: 41px;
        width: 285px;
        height: 34px;
    }

    @media (max-width: 480px) {
        margin-left: 1.8rem;
        width: 328px;
        height: 34px;

    }
`;

export const Cards = styled.div`
    display: flex;
    margin-top: 72px;
    margin-left: 17rem;
    width: 1284px;
    height: 195px;
    transition: width 0.3s;

.hidden{
    width: 0px;
    background: transparent;
}
     .show-step-1{
        animation: fade-in-right 1s linear ;
        
        @keyframes fade-in-right {
        from { opacity: 0; }
        to { opacity: 1; }
    }   

    }
    .show-step-2{
        animation: fade-in-right 1.8s linear ;
        
        @keyframes fade-in-right {
        from { opacity: 0; }
        to { opacity: 1; }
    }
}
    .show-step-3{
        animation: fade-in-right 2.6s linear ;
        
        @keyframes fade-in-right {
        from { opacity: 0; }
        to { opacity: 1; }
    }
}
    .show-step-4{
        animation: fade-in-right 3.2s linear ;
        
        @keyframes fade-in-right {
        from { opacity: 0; }
        to { opacity: 1; }
    } 
}

    @media (min-width: 480px) and (max-width: 1440px) {
        margin-left: 1rem;
        width: 1284px;
        height: 195px;;
    }
    @media (max-width: 480px) {
        width: 90%;
        margin-left: -1rem;
        display: block;
        justify-content: center;
        align-items: center;
    }
`;

export const Card = styled.div`
    width: 285px;
    /* height: 91px;  */
    margin-left: 48px;

    .title{
        color: white;
        font-weight: 400;
        font-size: 20px;
        margin-top: 20px;
    }
    .description{
        font-weight: 300;
        color: #f1f1f1;
        text-align: left;
        font-size: 16px;
        line-height: 24px;
        margin-top: 20px;
    }
    @media (min-width: 480px) and (max-width: 1440px) {
        width: 285px;
        height: 91px;
        margin-left: 28px;
    }
    
    @media (max-width: 480px) {
        width: 90%;
        height: 250px;
    }
`;

export const ImgCard = styled.img`
 
`;

export const Valorproposta = styled.div`

@media (max-width: 480px) {
    position: absolute;
    top: 1200px;
}
`;


