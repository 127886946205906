import {
  Container,
  Elementos,
  DescriçaoFooter,
  Endereco,
  Linkedin,
  Rodape,
  Simbolo,
  Oqentregamos,
  Proposta,
  FaleCaGente,
  Trabalhar,
  LinkInfo,
} from './styles';
import { Link } from 'react-router-dom'

import SimboloSVG from '../../assets/SVG/simbolo-match.svg'

import LinkedinSVG from '../../assets/SVG/LI-In-Bug-branco.svg'
import { useContato } from '../../contexts/contato';


function Footer() {

  const { handleModalContact } = useContato();

  return (
    <Container>
      <Rodape>
        <Elementos>
          <Link to="/">
            <Simbolo src={SimboloSVG} />
          </Link>
          <DescriçaoFooter>
            <LinkInfo><Link to="/politicas-de-privacidade">Política de Privacidade</Link></LinkInfo>
            <LinkInfo><Link to="/#o-que-entregamos">O que entregamos</Link></LinkInfo>
            <LinkInfo><Link to="/#o-que-move-a-match">O que move a match</Link></LinkInfo>
            <FaleCaGente onClick={handleModalContact}>Fale com a gente</FaleCaGente>
            <br />
            <Trabalhar href="https://match-mt.gupy.io/" target="_blank" rel="noreferrer">Quero trabalhar na match</Trabalhar>
          </DescriçaoFooter>
          <Endereco>
            <text className='textF'>Rua João Moura, 1144 – São Paulo/SP</text>
            <br />
            <text className='textF'>+55 11 3097-1057</text>
            <br />
          </Endereco>
          <a href="https://www.linkedin.com/company/match-marketing-tech/" target="_blank" rel="noreferrer"><Linkedin src={LinkedinSVG} /></a>
        </Elementos>
      </Rodape>
    </Container>
  );
};

export default Footer;
