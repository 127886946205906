import MetaTags from 'react-meta-tags';
import { Link } from 'react-router-dom';
import Header from '../../components/Header'
import Footer from '../../components/Footer'
import { Globalstyles } from '../../styles/Globalstyles'
import { Container, Button } from './styles';

function Sflive2023() {

  return (
    <>
      <MetaTags>
        <title>SalesForce Live 2023</title>
      </MetaTags>
      <Header />
      <Container>
          <h1> Página em Manutenção!</h1>
          <Link to="/">
            <Button>
              Voltar a página inicial
            </Button>
          </Link>
      </Container >
      <Footer />
      <Globalstyles />
    </>
  )};

export default Sflive2023;
