import styled from 'styled-components';
import * as I from '../../../components/Input';
import *  as S from '../../../components/Select';
import * as T from '../../../components/Textarea';
import * as C from '../../../components/Checkbox';
import { AiOutlineClose } from "react-icons/ai";

import { Form as F } from '@unform/web';


export const TextMotivo = styled.div`
    text-align: left;
    font-size: 20px;
    font-weight: bold;
    color: #000000;
    opacity: 1; 
    margin: 1.5% 0;

    @media (max-width: 480px) {
        width: 90%;
        margin-left: 1%;
    }
`;

export const ButaoEnviar = styled.div`
    margin: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 188px;
    height: 81px;
    margin-left: 5px;

    @media (max-width: 480px) {
        margin-left: 0px;  
    }
`;

export const Text = styled.label`
    text-align: left;
    font-size: 20px;
    font-weight: bold;
    color: #000000;
    opacity: 1; 
    margin: 1.5% 0;

    @media (max-width: 480px) {
        width: 90%;
        margin-left: 1%;
    }
    
    .Error {
        margin: 5px;
        font-size: 14px;
        color: red;
    }
`;

export const Input = styled(I.default)`
    margin-top: 5px;
    height: 40px;
    width: 539px;
    border: none;
    color: gray;
    font-size: 16px;

    @media (max-width: 480px) {
        width: 150%;
        margin-left: 10%;
    }
`;

export const Checkbox = styled(C.default)`
   
  appearance: none;
  width: 20px;
  height: 20px;
  
  background: #333;
  position: relative;

  &::before {
    content: "";
    width: 14px;
    height: 14px;
    position: absolute;
    background: #fff;
    top: 3px;
    left: 3px;
    
  }
  &:checked {
    &::after {
      content: "";
      width: 11px;
      height: 11px;
      position: absolute;
      background: #0000f2;
      top: 4.5px;
      left: 4.5px;
      
    }
  }
    
`;

export const Select = styled(S.default)`
    margin-top: -2px;
    margin-bottom: 3px;
    border: none;
    width: 20rem;
    color: gray;
    font-size: 16px;
    margin-left: -1%;


    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

 

    @media (max-width: 480px) {
        width: 90%;
        margin-left: 5%;
    }

    @media (min-width: 1921px) {
        margin-left: 0;
    }
`;
export const Textarea = styled(T.default)`
    
    padding-top: 1rem;
    border: none;
    width: 539px;
    height: 91px;
    color: gray;
    font-size: 16px;
    resize: none;

    @media (max-width: 480px) {
        width: 190%;
        margin-left: 30%;
    }
`;

export const Main = styled.div`
    height: 35%;
    width: 38%;
    margin-top: 5%;
    background: transparent;
    z-index: 99;
    /* opacity: 80%; */

    @media (min-width: 480px) and (max-width: 1440px) {
        margin-top: 2%;
    }

    @media (max-width: 480px) {
        margin-top: 20%;  
        margin-left: 10%;
        width: 110%;
        height: 210vw;
        align-items: center;
        justify-content: center;
   
    }
`;

export const Branco = styled.div`
  background: white;
  position: absolute;
  height: 125vw;
  width: 100%;
`;


export const Container = styled.div`
    height: 125vw;
    width: 100%;
    top: 0%;
    left: 0;
    position: fixed;
    z-index: 10;
    display: flex;
    justify-content: center;  
    background: transparent;
    opacity: 95%;
    z-index: 99;
    backdrop-filter: blur(2px);
  -webkit-backdrop-filter: blur(2px);

    @media (min-width: 480px) and (max-width: 1440px) {
        top: 0;
    }

    @media (max-width: 480px) {
        top: -5%;     
        width: 100%;
        height: 230vw;
        background: white;
        align-items: center;
        justify-content: center;
   
    }
`;

export const Closeicon = styled(AiOutlineClose)`
    width: 30px;
    height: 30px;
    color: black;
    top: 50px;
    left: 1483px;
    position: absolute;

    @media (min-width: 480px) and (max-width: 1440px) {
        top: 30px;
        left: 1206px;
        width: 25px;
        height: 25px;
    }

    @media (max-width: 480px) {
        left: 88%;
        top: 8.5%;
        width: 30px;
        height: 30px;
        z-index: 99;
    }
`;

export const Numerocontato = styled.div`
    width: 560px;
    height: 24px;
    margin-bottom: 3rem;

    .Vamos{
        text-align: left;
        font-size: 20px;
        font-weight: bold;
        color: #000000;
        opacity: 1; 
    }

    .Apresentaçao{
        text-align: left;
        font-size: 16px;
        line-height: 24px;
    }
    @media (min-width: 480px) and (max-width: 1440px) {
        margin-bottom: 10%;
    }

    @media (max-width: 480px) {
        margin-top: 84px;;
        margin-bottom: 15%;
        width: 328px;
        height: 67px;
    }
`;

export const Containerfooter = styled.div`
    margin-top: 10px;
    display: flex;

 
    @media (max-width: 480px) {
       display: block;
    }
`;

export const WrapperButton = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    align-content: center;
`

export const Form = styled(F)`
    display: flex;
    flex-direction: column;
    transform: scale(1.1);
    transform-origin:0 0;
    overflow-y: scroll;
    overflow-x: visible;
    width: 50vw;
    height: 33vw;

    textarea:focus, input:focus{
    outline: none;

    }
   
    iframe {
    transform: scale(0.84);
    transform-origin:0 0;
   }
   @media (min-width: 480px) and (max-width: 1440px) {
    transform: scale(0.98);
    transform-origin:0 0;
    margin-top: 0.1rem;
    width: 50vw;
    height: 40vw;
    overflow-y: scroll;
    overflow-x: hidden;
   }
   @media (max-width: 480px) {
    transform: scale(0.95);
    transform-origin:0 0;
    margin-top: -5rem;
    width: 100vw;
    height: 165.5vw;
    overflow-y: scroll;
    overflow-x: hidden;
   }
`

export const Botao = styled.button`
    margin: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 191px;
    height: 51px;
    margin-left: 5px;
    background: #5cf200;
    border: none;

    color: #333;
    font-size: 1rem;

    transition: width 0.2s, height 0.2s, margin-top 0.2s, margin-left 0.2s;
    &:disabled{
        background: #e1e1e1;
    }

    &:hover{
        width: 176px;
        height: 41px;
        @media (max-width: 480px) {

        }
    }

    @media (max-width: 480px) {
        margin-top: 5%;
    }
`;

export const Recptach = styled.div`
    width: 195px;
    height: 51px;
    background: blue;
`;

export const Politicaeprivacidade = styled.text`
    font-size: 12px;
    text-align: left;
    letter-spacing: 0px;
    margin-left: 0.7rem;
    color: #333;
    opacity: 1;
    width: 100%;
    
    @media (max-width: 480px) {
        margin-left: 0.7rem;
    }
`;

export const Termos = styled.a`
    text-decoration: underline;
    font-size: 13px;
    font-weight: 600;
`;

export const Checktermos = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    align-content: center;

    width: 30rem;

    @media (max-width: 480px) {
        margin-top: 10px;
        left: 40px;
        width: 21rem;
        height: 26px
    }
`;

export const Blue = styled.text`
    color: #0000f2;
    font-weight: 600;
`;