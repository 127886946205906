import styled from 'styled-components'

export const Wrapper = styled.div`
  margin: 0 auto 32px auto;
  max-width: 872px;
  padding: 0 16px;
  @media screen and (min-width: 1024px) {
    margin: 0 auto 48px auto;
  }
`
export const Title = styled.h2`
  font-size: 38px;
  font-weight: normal;
  line-height: 50px;
  @media screen and (min-width: 1024px) {
    font-size: 48px;
    line-height: 63px;
  }
`
export const SubTitle = styled.p`
  font-size: 18px;
  font-weight: 300;
  font-style: italic;
  line-height: 37px;
  @media screen and (min-width: 1024px) {
    font-size: 22px;
    line-height: 50px;
  }
`
export const InfoWrapper = styled.div`
  column-gap: 10%;
  display: flex;
  flex-wrap: wrap;
  font-size: 16px;
  font-weight: 300;
  padding-bottom: 32px;
  padding-top: 24px;
  row-gap: 8px;
  @media screen and (min-width: 1024px) {
    padding-bottom: 48px;
  }
`
export const Tags = styled.p`
  font-size: 16px;
  font-weight: bold;
  line-height: 24px;
  margin-bottom: 32px;
  @media screen and (min-width: 1024px) {
    margin-bottom: 48px;
  }
`
export const SocialNetworks = styled.div`
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  width: 170px;
`
export const IcoSocialNetworks = styled.img`
  height: 35px;
`
