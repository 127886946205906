import Footer from './components/Footer';
import Header from './components/Header';
import PropostaValor from './components/PropostaValor';
import Fourth from './pages/fourth';
import Home from './pages/Home';
import Second from './pages/second';
import { Globalstyles, Section } from './styles/Globalstyles';
import { useEffect } from 'react'

function App() {
  // const { showModalContact } = useContato();

  // console.log('showModalContact', showModalContact)

  useEffect(() => {
    console.log("Redirecting...")
    // Redirecionando site antigo da gupy para o atual caso insiram /gupy na URL
    if (window.location.href === 'https://match.mt/gupy/') {
      window.location.replace('https://match-mt.gupy.io/')
    }
  }, []);

  return (
    <div>
      <Section id='First'>
        <Home />
      </Section>
      <Section id='o-que-entregamos'>
        <Second />
      </Section>
      <Section>
        <PropostaValor />
      </Section>
      <Section id='Fourth'>
        <Fourth />
      </Section>
      <Footer />
      <Header />
      <Globalstyles />
    </div>
  );
}

export default App;
