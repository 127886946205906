import styled from 'styled-components';

export const ContainerAuthor = styled.div`
    margin: 32px auto;
    max-width: 872px;
    padding: 0 16px;
`

export const HolderTitle =  styled.div`
    margin-bottom: 24px;
`

export const AuthorName = styled.h2`
    color: #000;
    font-size: 28px;
    font-weigth: 700;
    line-heigth: 24px;
`

export const HolderContent = styled.div`
    display: flex;
`

export const HolderImage = styled.div`
    margin-right: 9px;

    @media (min-width: 768px) {
        margin-right: 32px;
    }
`

export const ImageAuthor = styled.img`
    border-radius: 50%;
    height: 110px;
    object-fit: cover;
    width: 110px;
`
export const AuthorDescription = styled.div`
    color: #000;
    font-size: 16px;
    font-weight: 300;
    line-height: 24px;
`