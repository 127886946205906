import styled from 'styled-components'

export const Wrapper = styled.div`
  margin: 0 auto;
  max-width: 872px;
  padding: 24px 16px;
`
export const Title = styled.h2`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  max-width: 428px;
  @media screen and (min-width: 1024px) {
    margin: 0;
  }
`
export const PostsWrapper = styled.div`
  column-gap: 16px;
  display: grid;
  justify-content: center;
  padding: 24px 0;
  @media screen and (min-width: 1024px) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
`
