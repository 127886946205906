import PostCard from '../PostCard'

import * as S from './styles'

export default function RelatedPosts({ content, basePostIndex, title }) {
  const previousPost =
    basePostIndex <= 0
      ? content[content.length - 1].meta
      : content[basePostIndex - 1].meta
  const nextPost =
    basePostIndex === content.length - 1
      ? content[0].meta
      : content[basePostIndex + 1].meta
  const relatedToFeaturedPost = [previousPost, nextPost]

  return (
    <S.Wrapper>
      <S.Title>{title}</S.Title>
      <S.PostsWrapper>
        {relatedToFeaturedPost.map((post, index) => (
          <div key={index}>
            <PostCard content={post} />
          </div>
        ))}
      </S.PostsWrapper>
    </S.Wrapper>
  )
}
