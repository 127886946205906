import styled from 'styled-components'

const Container = styled.div`
    background-color: #f9f9f9;
    padding-top: 88px;
`

const ErrorPage = styled.div`
    margin: 0 auto;
    max-width: 360px;
    padding: 40px 16px;

    @media (min-width: 768px) {
        display: flex;
        max-width: 985px;
        padding: 137px 16px 121px;
    }

    @media (min-width: 1024px) {
        padding: 137px 0 121px;
    }
`

const TextContent = styled.div`
    @media (min-width: 768px) {
        margin-right: 178px;
    }
`

const ErrorMessage = styled.div`
    h1, h3 {
        color: #000;
        font-size: 20px;
        font-weight: 700;
        line-height: 26px;
    }

    h3 {
        margin-bottom: 40px;

        @media (min-width: 768px) {
            margin-bottom: 22px;
        }
    }
`

const CodeSection = styled.div`
    display: flex;
    justify-content: space-between;
    margin: 16px 0;
`

const Code = styled.div`
    h2 {
        color: #000;
        font-size: 89px;
        line-height: 117px;

        @media (min-width: 768px) {
            font-size: 228px;
            line-height: 301px;
        }
    }
`

const OnlyMobileImage = styled.div`
    display: block;

    @media (min-width: 768px) {
        display: none;
    }
`

const OnlyDesktopImage = styled.div`
    display: none;

    @media (min-width: 768px) {
        display: block;
    }
`

const ButtonHolder = styled.div`
    display: inline-block;
    height: 51px;
    position: relative;
    width: 186px;
`

const ButtonText = styled.div`
    display: inline-block;
    height: 51px;
    width: 186px;

    &:after {
        color: #fff;
        content: '${(props) => props.text}';
        font-family: 'Roboto', sans-serif;
        font-size: 16px;
        font-weight: 400;
        height: 100%;
        left: 50%;
        line-height: 51px;
        pointer-events: none;
        position: absolute;
        text-align: center;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
    }

    a {
      display: inline-block;
      height: 51px;
      text-decoration: none;
      transform: scale(1);
      transition: all 0.3s ease;
      width: 186px;

      &:hover {
        transform: scale(0.9);

        button {
          line-height: 41px;
        }
      }
    }

    button {
        background-color: #f06600;
        border: 0;
        color: transparent;
        cursor: pointer;
        font-size: 0;
        height: 100%;
        text-align: center;
        transition: all 0.3s ease;
        width: 100%;
    }
`

const Image = styled.img`
    height: 125px;
    width: 125px;

    @media (min-width: 768px) {
        height: 375px;
        width: 100%;
    }

    @media (min-width: 1024px) {
        height: 375px;
        width: 375px;
    }
`

export { Container, ErrorPage, TextContent,
        ErrorMessage, CodeSection, Code,
        OnlyMobileImage, OnlyDesktopImage,
        ButtonText, ButtonHolder, Image }
