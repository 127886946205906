import React, { useRef, useEffect } from "react";
import {
  OptionTypeBase,
  Props as SelectProps,
} from "react-select";

import { useField } from "@unform/core";

import { SelectWrapper, Error, ReactSelect } from "./styles";

interface Props extends SelectProps<OptionTypeBase> {
  name: string;
  label: string;
  placeholder: string;
}

const Select: React.FC<Props> = ({ placeholder, label, name, ...rest }) => {
  const selectRef = useRef(null);
  const { fieldName, defaultValue, registerField, error } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: selectRef.current,
      getValue: (ref: any) => {
        if (rest.isMulti) {
          if (!ref.state.value) {
            return [];
          }
          return ref.state.value.map((option: OptionTypeBase) => option.value);
        }
        if (!ref.state.value) {
          return "";
        }
        return ref.state.value.value;
      },
    });
  }, [fieldName, registerField, rest.isMulti]);

  return (
    <SelectWrapper>
      {label && <label htmlFor={fieldName}>{label}</label>}

      <ReactSelect
        defaultValue={defaultValue}
        ref={selectRef}
        placeholder={placeholder}
        classNamePrefix="react-select"
        {...rest}
        theme={(theme: any) => ({
          ...theme,
          colors: {
            ...theme.colors,
            primary25: '#292929',
            primary: '#292929',
            neutral0: 'white',
            primary50: '#292929',
          },
        })}
      />

      {error && (
        <Error>
          <span>{error}</span>
        </Error>
      )}
    </SelectWrapper>
  );
};

export default Select;
