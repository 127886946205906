import MetaTags from 'react-meta-tags';

import { Container, Title, Description, PageBox } from './styles'

import PoliciesPDF from '../../assets/PDF/01-politica-interna-de-privacidade.pdf'

import { Globalstyles } from '../../styles/Globalstyles';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import FormContato from '../Home/FormContato';
import { useContato } from '../../contexts/contato';


function Policies() {
  const { showModalContact, handleModalContact } = useContato();

  return (
    <PageBox>
      <MetaTags>
        <title>Política de Privacidade</title>
      </MetaTags>
      <Header />
      <Container>
        <Globalstyles />
          <Title>
            Política de Privacidade da Match.mt
          </Title>
          <Description>
          Esta Política de Privacidade contém informações importantes sobre como utilizamos suas informações pessoais. Respeitamos a privacidade dos indivíduos que interagem com o nosso negócio e quando você decide nos fornecer informações sobre si mesmo, você confia que agiremos de maneira responsável com essas informações.
          </Description>
          <Description>
            Se você tiver alguma dúvida, comentário ou preocupação sobre qualquer aspecto desta política ou como a Match.mt lida com suas informações, envie um e-mail para nossa equipe de privacidade em <a className='link' href="mailto:dpo@match.mt">dpo@match.mt</a>.
          </Description>
          <Description>
            Veja na íntegra nossa Política de Privacidade clicando <a className='link' rel="noreferrer" href={PoliciesPDF} target='_blank'><strong>aqui</strong></a>.
          </Description>
          {showModalContact ?
            <FormContato onClose={handleModalContact} />
          : null}
      </Container>
      <Footer />
    </PageBox>
  );
};

export default Policies;
