import { useProposta } from "../../contexts/proposta"
import Disclaimer from "../Disclaimer"
import Pizza from "../Pizza"
import { PropostaWrapper, Text, Wrapper, Background, Mobile, BackgroundGradient, Blue } from "./styles"
import BG from '../../assets/SVG/bg.svg'

import PizzaMobile from "../PizzaMobile"

const PropostaValor = () => {

  const { proposta } = useProposta()
  return (
    <>
      <Wrapper>
        <Background src={BG} alt="Top" />
        <BackgroundGradient />
        <Text>O que move a <Blue>match</Blue></Text>
        <PropostaWrapper>
          <Pizza />
          <Disclaimer disclaimer={proposta} />
        </PropostaWrapper>
      </Wrapper>
      <Mobile>
        <PizzaMobile />
      </Mobile>
    </>
  )
}

export default PropostaValor;