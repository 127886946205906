import { Link } from 'react-router-dom'
import MetaTags from 'react-meta-tags'

import { Globalstyles } from '../../styles/Globalstyles'

import { Container, ErrorPage, TextContent,
          ErrorMessage, CodeSection, Code,
          OnlyMobileImage, OnlyDesktopImage,
          ButtonText, ButtonHolder, Image } from './styles'

import Header from '../../components/Header'
import Footer from '../../components/Footer'

import ErrorImage from '../../assets/SVG/error-image.svg'

export default function Error() {
  return (
    <>
      <MetaTags>
        <title>404</title>
        <meta name="prerender-status-code" content="404" />
      </MetaTags>

      <Header />
      <Container>
        <ErrorPage>
          <TextContent>
            <ErrorMessage>
              <h1>Página não encontrada</h1>
            </ErrorMessage>

            <CodeSection>
              <Code>
                <h2>404</h2>
              </Code>

              <OnlyMobileImage>
                <Image src={ErrorImage} alt="Página não encontrada" />
              </OnlyMobileImage>
            </CodeSection>

            <ErrorMessage>
              <h3>Desculpa, não encontramos a página que você procura, mas temos muitos posts interessantes para você!</h3>
            </ErrorMessage>

            <ButtonHolder>
              <ButtonText text={'Voltar para a home'}>
                <Link to="/blog">
                  <button>Voltar para a home</button>
                </Link>
              </ButtonText>
            </ButtonHolder>
          </TextContent>

          <OnlyDesktopImage>
            <Image src={ErrorImage} alt="Página não encontrada" />
          </OnlyDesktopImage>
        </ErrorPage>
      </Container>
      <Footer />
      <Globalstyles />
    </>
  )
}
