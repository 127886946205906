import styled, { createGlobalStyle } from "styled-components";

export const Globalstyles = createGlobalStyle`
    *{
        margin: 0;
        padding: 0;
        overflow: unset;
    }
    *,button,input{
        font-family: 'Roboto', sans-serif;
    }

`;

export const Section = styled.div`
    position: relative;
`;