import {
  createContext,
  useState,
  useContext,
} from "react";

interface Proposta {
  value: number;
}

interface PropostaContextData {
  proposta: Proposta | null;
  handleProposta(value: Proposta): void;
}

const PropostaContext = createContext<PropostaContextData>({} as PropostaContextData);

export const PropostaProvider: React.FC = ({ children }) => {
  const [proposta, setProposta] = useState<Proposta | null>(null);

  const handleProposta = (value: Proposta) => {
    setProposta(value)
  }

  return (
    <PropostaContext.Provider
      value={{ proposta, handleProposta }}
    >
      {children}
    </PropostaContext.Provider>
  );
};

export function useProposta() {
  const context = useContext(PropostaContext);

  return context;
}
