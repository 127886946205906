import MetaTags from 'react-meta-tags'
import { useParams, Navigate } from 'react-router-dom'
import { Globalstyles } from '../../styles/Globalstyles'
import { Container } from './styles'

import Header from '../../components/Header'
import Post from '../../components/Post'
import AboutAuthor from '../../components/AboutAuthor'
import RelatedPosts from '../../components/RelatedPosts'
import Footer from '../../components/Footer'

import content from '../../data/blog.json'
import BackButton from '../../components/BackButton'

export default function Blog() {
  const { topicId } = useParams()
  const arrCurrentPost = content.posts.filter(
    (post) => post.slug === `/blog/${topicId}`
  )
  const objCurrentPost = arrCurrentPost[0]
  return (
    <>
      {arrCurrentPost.length === 0 ? (
        <Navigate to='/error' />
      ) : (
        <>
          <MetaTags>
            <title>{objCurrentPost.meta.title}</title>
            <meta name='description' content={objCurrentPost.meta.description} />
            <meta name='keywords' content={objCurrentPost.meta.keywords} />
            <meta property='og:title' content={objCurrentPost.meta.title} />
            <meta property='og:url' content={`https://www.match.mt${objCurrentPost.meta.slug}`} />
            <meta property='og:image' itemProp='image' content={objCurrentPost.meta.image} />
            <meta property='og:image:type' content='image/png' />
            <meta property='og:image:width' content='300' />
            <meta property='og:image:height' content='300' />
            <meta property='og:description' content={objCurrentPost.meta.description} />
            {objCurrentPost.about_the_author && (
              <meta name='author' content={objCurrentPost.about_the_author.author} />
            )}
          </MetaTags>
          <Header />
          <Container>
            <BackButton />
            <Post content={objCurrentPost} />
            {objCurrentPost.about_the_author && (
              <AboutAuthor content={objCurrentPost.about_the_author} />
            )}
            <RelatedPosts
              content={content.posts}
              basePostIndex={objCurrentPost.id - 1}
              title='Posts relacionados'
            />
          </Container>
          <Footer />
          <Globalstyles />
        </>
      )}
    </>
  )
}
