import { useState } from 'react'
import PostCard from '../PostCard'
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry'

import * as S from './styles'

export default function AllPosts({ content }) {
  const initialVisiblePosts = 4
  const [visiblePosts, setVisiblePosts] = useState(initialVisiblePosts)
  const loadMorePostsHandler = () => {
    setVisiblePosts(visiblePosts + initialVisiblePosts)
  }

  return (
    <S.Wrapper>
      <S.Title>Todos posts</S.Title>
      <S.PostsWrapper>
        <ResponsiveMasonry columnsCountBreakPoints={{ 350: 1, 1024: 2 }}>
          <Masonry columnsCount={2} gutter='16px'>
            {content.map((post, index) => (
              <div key={index}>
                {index < visiblePosts && <PostCard content={post.meta} />}
              </div>
            ))}
          </Masonry>
        </ResponsiveMasonry>
      </S.PostsWrapper>
      {content.length > visiblePosts && (
        <S.ButtonWrapper>
          <S.Button onClick={loadMorePostsHandler}>
            Carregar mais posts
          </S.Button>
        </S.ButtonWrapper>
      )}
    </S.Wrapper>
  )
}
