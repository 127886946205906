import styled from 'styled-components'

export const Wrapper = styled.div`
  margin: 0 auto;
  max-width: 872px;
  padding: 24px 16px;
`
export const PostsWrapper = styled.div`
  margin: 0 auto;
  max-width: 428px;
  @media screen and (min-width: 1024px) {
    max-width: none;
  }
`
export const Title = styled.h2`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  max-width: 428px;
  padding-bottom: 24px;
  @media screen and (min-width: 1024px) {
    margin: 0;
  }
`
export const ButtonWrapper = styled.div`
  align-items: center;
  display: flex;
  height: 50px;
  padding: 24px 0;
  width: 100%;
`
export const Button = styled.button`
  background-color: #000000;
  border: none;
  color: #FFFFFF;
  cursor: pointer;
  font-size: 16px;
  height: 50px;
  margin: 0 auto;
  transition: all 0.1s ease-out;
  width: 255px;
  &:hover {
    height: 41px;
    width: 218px;
  }
`
