import MetaTags from 'react-meta-tags'
import { Globalstyles } from '../../styles/Globalstyles'
import { Container } from './styles'

import Header from '../../components/Header'
import TopFeature from '../../components/TopFeature'
import RelatedPosts from '../../components/RelatedPosts'
import AllPosts from '../../components/AllPosts'
import Footer from '../../components/Footer'
import FormContato from '../Home/FormContato'

import { useContato } from '../../contexts/contato'

import content from '../../data/blog.json'

export default function Blog() {
  const { showModalContact, handleModalContact } = useContato()

  return (
    <>
      <MetaTags>
        <title>Blog Match</title>
      </MetaTags>
      <Header />
      <Container>
        <TopFeature content={content} />
        <RelatedPosts
          content={content.posts}
          basePostIndex={content.featured_post - 1}
          title='Principais posts'
        />
        <AllPosts content={content.posts} />
      </Container>
      <Footer />
      <Globalstyles />

      {showModalContact ? <FormContato onClose={handleModalContact} /> : null}
    </>
  )
}
