import { useState } from 'react';

import { Closeicon, Telavideo, Container, Visual, ConteinerModal, Control, DescriptionFourth, Source, Video, Blue } from './styles';

import ImagemV from '../../assets/PNG/Videoimagem.png'

function Fourth() {
  const [showModal, setShowModal] = useState(false);

  function handleClick() {
    setShowModal(true)
    document.body.style.overflow = 'hidden';
  }

  function handleClose() {
    setShowModal(false)
    document.body.style.overflow = 'auto';
  }

  return (
    <Container>
      <DescriptionFourth>
        <text className='Textofinal'>Nós somos a <Blue>match</Blue>.</text>
        <br />
        <text className='Textofinal'>Entregamos experiências e vamos além.</text>
        <br />
        <Visual>Confira o nosso novo visual.</Visual> 
      </DescriptionFourth>
      <Video onClick={handleClick}>
          <Telavideo src={ImagemV}></Telavideo>
      </Video>
      {showModal ?
        <ConteinerModal>
          <Closeicon onClick={handleClose} />
          <Control controls autoPlay>
            <Source src="videos/VideoMatch.mp4" type="video/mp4" />
          </Control >
        </ConteinerModal>
        : null
      }
    </Container >
  );
};

export default Fourth;
