import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
`

export const TextareaWrapper = styled.div`
  display: flex;
  flex-direction: column;

  textarea {
    margin: 0;
    min-height: 60px;
  }
`