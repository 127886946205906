import styled from 'styled-components'

export const DisclaimerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  align-content: center;
  justify-content: center;
  justify-items: flex-start;
  width:50%;
  height:600px;
  z-index: 95;

  .tiny{
    width: 18.8rem;
    left: 47.5rem;

    @media (min-width: 480px) and (max-width: 1440px) {
      left: 30rem;
      width: 20rem;
    }
  }

  .mid{
    width: 13.5rem;
    left: 52.8rem;

    @media (min-width: 480px) and (max-width: 1440px) {
      left: 36.5rem;
      width: 13.5rem;
    }
  }

  .major{
    width: 7.5rem;
    left: 58.8rem;

    @media (min-width: 480px) and (max-width: 1440px) {
      left: 42.5rem;
      width: 7.5rem;
    }
  }
`

export const Text = styled.p`
  color: #f1f1f1;
  font-size: 16px;
  font-weight: 300;
  line-height: 24px;
  text-align: left;
  margin-bottom: 1rem;
  margin-left: 140px;
  width: 430px;
`

export const Title = styled.h2`
  color: #f1f1f1;
  font-size: 24px;
  font-weight: 600;
  
  margin-bottom: 1rem;
  margin-left: 140px;
  width: 236px;
  height: 29px;
`

export const Linha = styled.div`
  height: 0px;
  background: #4D4D4D;
  border: 0.1rem solid #4D4D4D;
  opacity: 1;
  left: 20rem;
  position: absolute;

  @media (min-width: 480px) and (max-width: 1440px) {
      left: 20rem;
      border: 0.1rem solid #4D4D4D;
      opacity: 1;
  }
  @media (max-width: 480px) {
      display: none;
    }
`;