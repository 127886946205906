import {
  Container,
  Top,
  Titulopagina,
  Cards,
  Card,
  ImgCard,
  Valorproposta
} from './styles';

import { useRef } from 'react'

import Experiencia from '../../assets/SVG/icon-experiencia.svg'
import Analytics from '../../assets/SVG/icon-analytics.svg'
import Estrategia from '../../assets/SVG/icon-estrategia.svg'
import Tecnologia from '../../assets/SVG/icon-tecnologia.svg'
import useOnScreen from '../../hooks/useOnScreen';


function Second() {

  const ref = useRef(null)
  const isVisible = useOnScreen(ref)

  return (
    <Container ref={ref}>
      <Top/>
      <Titulopagina>O que entregamos</Titulopagina>
      <Cards>
        <Card className={isVisible ? "show-step-1" : "hidden"}>
          <ImgCard src={Experiencia} alt='Experiencia' />
          <h1 className='title'>Experiência Digital</h1>
          <p className='description'>Desenvolvimento de soluções customizadas, desde o design até a codificação, utilizando as mais atuais e melhores práticas de mercado.</p>
        </Card>
        <Card className={isVisible ? "show-step-2" : "hidden"}>
          <ImgCard src={Analytics} alt='Analytics' />
          <h1 className='title'>Analytics e Data Science</h1>
          <p className='description'>Análises de dados e criação de modelos para entender o comportamento dos clientes e direcionar as estratégias de ativação.</p>
        </Card>
        <Card className={isVisible ? "show-step-3" : "hidden"} >
          <ImgCard src={Tecnologia} alt='Tecnologia' />
          <h1 className='title'>Tecnologias para o Marketing</h1>
          <p className='description'>Implementação, arquitetura de soluções e operação de plataformas de marketing para materializar estratégias de relacionamento.</p>
        </Card>
        <Card className={isVisible ? "show-step-4" : "hidden"} >
          <ImgCard src={Estrategia} alt='Estrategia' />
          <h1 className='title'>Estratégias de relacionamento</h1>
          <p className='description'>Olhar 360º para o cliente utilizando vários tipos de canais para otimização da comunicação e melhor resultado.</p>
        </Card>
      </Cards>
      <Valorproposta id='o-que-move-a-match'></Valorproposta>
    </Container >
  );
};

export default Second;
