import styled from 'styled-components';

import { AiOutlineClose } from "react-icons/ai";

import AnchorLink from 'react-anchor-link-smooth-scroll';

export const Container = styled.div`
    height: 100vw;
    width: 100%;
    top: 0px;
    left: 0;
    position: absolute;
    display: flex;
    justify-content: center;
    opacity: 95%;
    background: white;
    backdrop-filter: blur(2px);
  -webkit-backdrop-filter: blur(2px);

    @media (max-width: 480px) {
        width: 100%;
        top: 0;
        height: 210vw;
    }
`;

export const TextoT = styled.text`
    width: 650px;
    height: 58px;
    font-weight: 400;
    position: absolute;
    text-align: left;
    font-size: 48px;
    letter-spacing: 0px;
    opacity: 1;

    @media (max-width: 480px) {
        font-size: 38px;
        width: 300px;
    }
`;

export const Texto = styled.text`
    width: 650px;
    height: 58px;
    font-weight: 400;
    position: absolute;
    text-align: left;
    font-size: 48px;
    letter-spacing: 0px;
    opacity: 1;

    @media (max-width: 480px) {
        font-size: 38px;
        width: 320px;
    }
`;

export const Square = styled.div``;

const OQueEntregamosStyles = `
  top: 255px;
  left: 413px;
  width: 650px;
  height: 58px;
  position: absolute;
  color: #000000;
  transition: all 0.2s ease-in-out;

  :hover{
    color: #0000f2;

    @media (max-width: 480px) {
    color: #000000;
  }
  }

  @media (min-width: 480px) and (max-width: 1440px) {
      top: 150px;
      left: 147px;
      width: 650px;
      height: 58px;
  }
  @media (max-width: 480px) {
      top: 150px;
      left: 16px;
  }
`

const PropostaStyles = `
top: 355px;
left: 413px;
position: absolute;
color: #000000;
transition: all 0.2s ease-in-out;

:hover{
  color: #0000f2;

  @media (max-width: 480px) {
    color: #000000;
}
}

  @media (max-width: 480px) {
    top: 230px;
    left: 16px;
    color: #000000;
}

@media (min-width: 480px) and (max-width: 1440px) {
    top: 250px;
    left: 147px;
    width: 650px;
    height: 58px;
}
@media (max-width: 480px) {
    top: 230px;
    left: 16px;
}
`

export const Oqentregamos = styled(AnchorLink)`${OQueEntregamosStyles}`
export const LinkOqentregamos = styled.a`${OQueEntregamosStyles}`

export const Proposta = styled(AnchorLink)`${PropostaStyles}`
export const LinkProposta = styled.a`${PropostaStyles}`

export const FaleCaGente = styled.div`
    top: 455px;
    left: 413px;
    position: absolute;
    transition: all 0.2s ease-in-out;
    cursor: pointer;

    :hover{
      color: #0000f2;

      @media (max-width: 480px) {
        color: #000000;
    }
}

    @media (min-width: 480px) and (max-width: 1440px) {
        top: 350px;
        left: 147px;
        width: 650px;
        height: 58px;
}
  @media (max-width: 480px) {
        top: 350px;
        left: 16px;
  }

`;
export const Trabalhar = styled.a`
    top: 555px;
    left: 413px;
    position: absolute;
    color: #000000;
    transition: all 0.2s ease-in-out;

    :hover{
      color: #0000f2;

      @media (max-width: 480px) {
        color: #000000;
    }
}

    @media (min-width: 480px) and (max-width: 1440px) {
        top: 450px;
        left: 147px;
}

  @media (max-width: 480px) {
        top: 420px;
        left: 16px;

  }
`;

export const Endereco = styled.div`
    position: absolute;
    font-weight: 400;
    top: 700px;
    left: 413px;
    width: 427px;
    height: 43px;
    text-align: left;
    font-size: 16px;
    color: black;

    @media (min-width: 480px) and (max-width: 1440px) {
        top: 550px;
        left: 136px;
        width: 427px;
        height: 43px;
  }
    @media (max-width: 480px) {
        top: 550px;
        left: 16px;
        width: 253px;
        height: 43px;
    }
`;

export const Closeicon = styled(AiOutlineClose)`
    width: 40px;
    height: 40px;
    color: black;
    top: 50px;
    left: 1483px;
    position: absolute;

    @media (min-width: 480px) and (max-width: 1440px) {
        top: 50px;
        left: 1206px;
        width: 30px;
        height: 30px;
  }

  @media (max-width: 480px) {
    top: 37px;
    left: 320px;
    width: 30px;
    height: 30px;

  }
`;

export const Linkedin = styled.img`
    position: absolute;
    left: 601px;
    width: 32px;
    height: 32px;

    @media (min-width: 480px) and (max-width: 1440px) {

}
  @media (max-width: 480px) {
    top: 0rem;
    left: 300px;
    width: 32px;
    height: 32px;
  }
 `;


export const Logo = styled.img`
margin-top: 52px;
margin-left: -835px;
width: 157px;
height: 24px;

@media (min-width: 480px) and (max-width: 1440px) {
    margin-top: 42px;
    margin-left: -930px;
    width: 157px;
    height: 24px;

}
@media (max-width: 480px) {
    margin-top: 25px;
    margin-left: -220px;
    width: 157px;
    height: 24px;
}
`;
