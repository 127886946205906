import * as S from './styles'

export default function AboutAuthor ({ content }) {

    return (
        <S.ContainerAuthor>
            <S.HolderContent>
                {content.image && 
                    <S.HolderImage>
                        <S.ImageAuthor src={ content.image } alt="Imagem Autor"/>
                    </S.HolderImage>
                }
                <div>
                    <S.AuthorName>{ content.author }</S.AuthorName>
                    <S.AuthorDescription>{ content.description }</S.AuthorDescription>
                </div>                
            </S.HolderContent>
        </S.ContainerAuthor>
    )
}
