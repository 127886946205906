import {
  createContext,
  useState,
  useContext,
} from "react";

interface Contato {
  showModalContact: boolean;
}

interface ContatocontextData {
  showModalContact: boolean | null;
  handleModalContact(): void;
}

const ContatoContext = createContext<ContatocontextData>({} as ContatocontextData)

export const ContatoProvider: React.FC = ({ children }) => {

  const [showModalContact, setShowModal] = useState(false);

  function handleModalContact() {
    if(showModalContact == false){
      setShowModal(!showModalContact)
      document.body.style.overflow = "hidden";
    }
    if(showModalContact == true){
      setShowModal(!showModalContact)
      document.body.style.overflowY = "scroll";
      document.body.style.overflowX = "hidden";
    }
  }

  return (
    <ContatoContext.Provider
      value={{ showModalContact, handleModalContact }}
    >
      {children}
    </ContatoContext.Provider>
  );
};

export function useContato() {
  const context = useContext(ContatoContext);

  return context;
}




