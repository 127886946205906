import { Link } from 'react-router-dom'

import { Container, Title, Description, ImageContainer,
        Image, ButtonHolder, ButtonText } from './styles'

export default function TopFeature({ content }) {
  const featuredPostIndex = content.featured_post - 1
  const featuredPost = content.posts[featuredPostIndex].meta

  const scrollToTop = () =>{
    window.scrollTo({
      top: 0, 
      behavior: 'auto'
    });
  };

  return (
    <Container>
      <Title>{featuredPost.title}</Title>
      <Description>{featuredPost.description}</Description>

      <ImageContainer backgroundColor={featuredPost.color}>
        <Image src={featuredPost.image} alt={featuredPost.title} />
      </ImageContainer>

      <ButtonHolder>
        <ButtonText
          backgroundColor={featuredPost.color}
          text="Ler conteúdo completo"
        >
          <Link to={featuredPost.slug}>
            <button onClick={scrollToTop}>Ler conteúdo completo</button>
          </Link>
        </ButtonText>
      </ButtonHolder>
    </Container>
  )
}
